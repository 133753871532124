import axios from "axios";

const BASE_URL = "https://dev.moons.rocks/login/account-info";


export const updateData = async (data) => {

  // b4app -> user dev-moons -> username is the mail
  // tomar el session token para posteriores peticiones

  try {
    const response = await axios.put(BASE_URL, data);
    alert(`updated!`)
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
